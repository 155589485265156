import request from '@/utils/request'
const BASE_URL = '/wjhhjk'
const PROVINCE_BASE_URL = '/jssxfhh'

export class Project {
  /**
   * @description  项目列表查询
   * @author hu_ty
   * @since
   * @param {*}
   *
   */
  static getList = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/pro/listAll`,
      data: params
    })
  }
  static getListByuser = () => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/pro/listByCurrentUser`
    })
  }

  /**
   * @description 项目信息保存（项目基础信息）
   * @author hu_ty
   * @since
   * @param {*}
   *
   */
  static savePro = (params) => {
    return request.post(`${BASE_URL}/scheme/pro/save`, params)
  }

  /**
   * 下面的接口我先写着 到时候位置再调整
   */

  //标准规范
  static guideList = () => {
    return request({
      method: 'get',
      url: `${BASE_URL}/dict/common/guideList`
    })
  }

  //查询所有方案
  static getAllScheme = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/scheme/listPage`,
      data: params
    })
  }

  //保存方案
  static saveScheme = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/scheme/save`,
      data: params
    })
  }
  //删除方案
  static delScheme = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/scheme/deleteById/${id}`
    })
  }

  // ============================================ user
  //查询所有用户
  static getAllUSer = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/user/listAll`,
      data: params
    })
  }
  //添加、修改用户
  static addUSer = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/user/save`,
      data: params
    })
  }
  //删除用户
  static delUSer = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/user/deleteById/${id}`
    })
  }

  // ============================================ obj
  /**
   * {
        "area": 0,
        "comments": "string",
        "createTime": "2022-07-02T06:50:24.888Z",
        "geom": "string",
        "id": 0,
        "length": 0,
        "name": "string",
        "orderBy": "string",
        "pageNum": 0,
        "pageSize": 0,
        "queryExt": {},
        "rlType": 0,
        "sign": "string",
        "volume": 0
      }
   */
  //添加、修改对象
  static saveObj = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/obj/save`,
      data: params
    })
  }
  //删除对象
  static delObj = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/obj/deleteById/${id}`
    })
  }
  //查询所有
  static getAllObj = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/obj/listAll`,
      data: params
    })
  }

  // ============================================ jianCeDian
  /**
* {
    "createTime": "2022-07-02T10:07:28.082Z",
    "delegateLength": 0, //代表河长
    "dnwrTimes": 0, //底泥污染状况 频次
    "dqswTimes": 0,  // 底栖生物指数 频次
    "fyzwTimes": 0, //浮游植物 频次
    "id": 0,
    "lat": 0,
    "lon": 0,
    "objId": 0, //对象id
    "orderBy": "string",
    "pageNum": 0,
    "pageSize": 0,
    "queryExt": {},
    "schemeId": 0, //
    "sign": "string",
    "szjcItems": 0,
    "szylTimes": 0, //水质优劣程度 频次
    "waterFunEvaluateId": 0,
    "waterFunFlag": true, //水功能区标识符
    "waterFunLength": 0, // 水功能区代表长度
    "zszlTimes": 0 //着生藻类 频次
  }
*/

  //添加、修改监测点
  static saveJianCeDian = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/site/save`,
      data: params
    })
  }
  //删除监测点
  static delJianCeDian = (id) => {
    return request({
      method: 'get',
      url: `${BASE_URL}/scheme/site/deleteById/${id}`
    })
  }
  //查询所有监测点
  static getAllJianCeDian = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/scheme/site/listPage`,
      data: params
    })
  }

  //======================== 水功能区
  static getjiZhunList = (params) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/dict/shuiGongNengQuPingJiaJiZhun/jiZhunList`,
      data: params
    })
  }

  static getAlljiZhunItems = (id) => {
    return request({
      method: 'post',
      url: `${BASE_URL}/dict/shuiGongNengQuPingJiaJiZhun/jiZhunItems/${id}`
    })
  }

  /** 获取二维码对应评估对象评分信息 */
  static getQrcodeInfo = (id) => {
    return request.get(`${BASE_URL}/scheme/scheme/findByToken/` + id)
  }
}

/**
 * @description  省级项目信息类
 * @author hu_ty
 * @since
 * @param {*}
 *
 */
export class ProvinceProject {
  /**
   * @description  项目列表查询
   * @author hu_ty
   * @since
   * @param {*}
   *
   */
  static getList = (params) => {
    return request({
      method: 'post',
      url: `${PROVINCE_BASE_URL}/scheme/pro/listAll`,
      data: params
    })
  }
  static getListByuser = () => {
    return request({
      method: 'get',
      url: `${PROVINCE_BASE_URL}/scheme/pro/listByCurrentUser`
    })
  }
}
