<template>
  <div class="title-info">
    <img class="left-icon" src="../../assets/img/title/left-icon.png" alt="" srcset="" />
    <img class="right-icon" src="../../assets/img/title/right-icon.png" alt="" srcset="" />
    <div class="bottom-border"></div>
    <div class="title">{{ title }}</div>
  </div>
</template>
<script>
  export default {
    name: '',
    components: {},
    props: {
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {}
  }
</script>
<style lang="less" scoped>
  .title-info {
    height: 40px;
    position: relative;
    margin-top: 16px;
    width: 100%;
    .left-icon {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .right-icon {
      position: absolute;
      right: 0;
    }

    .bottom-border {
      position: absolute;
      left: 47px;
      bottom: 0px;
      width: calc(100% - 78px);
      height: 1px;
      opacity: 0.2;
      background: rgba(64, 158, 255, 1);
    }
    .title {
      margin-top: 6px;
      height: 34px;
      margin-left: 2px;
      color: rgba(64, 158, 255, 1);
      font-weight: 500;
      font-size: 20px;
      line-height: 34px;
    }
  }
</style>
